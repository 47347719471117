import { Box } from "@mui/material"
import { useState, useEffect } from "react"
import styles from './Card.module.css'
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ReactGA from 'react-ga4';

const Card = (props) => {
    let [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    useEffect(() => {
        const handleEsc = (event) => {
           if (event.keyCode === 27) {
            setOpen(false)
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
      }, []);
    
    function changeIndex(direction) {
        if (direction === 'left') {
            if(index === 0){
                setIndex(props.Images.length -1)
            }
            else if (index > 0){
                setIndex(index -1)
            }
        }
        else if (direction === 'right') {
            if(index === props.Images.length -1) {
                setIndex(0)
            }
            else if (index < props.Images.length -1) {
                setIndex(index +1)
            }
        }
    }

    function GoogleAnalitics() {
        ReactGA.event({
          action: `${props.Type}-click`,
          category: 'offer-category',
          label: 'offer',
          value: 'xxxx'
        })
        setOpen(!open)
      }

    return (
        
        <Box sx={{boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)', gap: '1px',borderRadius: '5px',width: open === true?'300px': '250px',height: open === true? '350px': '250px',display: 'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'column',}}> 
        {!open && <AspectRatioIcon fontSize="small" sx={{alignSelf: 'flex-end', marginRight: '5px', marginTop: '5px', cursor: 'pointer'}} onClick={() => setOpen(!open)}/>}
        {open === true && <CloseIcon fontSize="medium" onClick={() => setOpen(!open)} sx={{cursor: 'pointer', alignSelf: 'flex-end', marginRight: '5px'}}/>}
        {open === true && <div className={styles.ArrowsContainer}>
            <KeyboardDoubleArrowLeftIcon fontSize="large" onClick={() => changeIndex('left')} sx={{cursor: 'pointer'}}/>
            <KeyboardDoubleArrowRightIcon fontSize="large" onClick={() => changeIndex('right')} sx={{cursor: 'pointer'}}/>
            </div>}
        <div className={open?styles.ImageBoxBig : styles.ImageBox}>
            <img alt="product" className={styles.Image} src={props.Images[index]} onClick={() => GoogleAnalitics()}/>
        </div>
        <a href={props.Link} className={styles.Description}>{props.Type}</a>
        <a href={props.Link} className={styles.SeeMore}>{props.ClickToSeeMore}</a>
        </Box>
    )
}

export default Card;